<template>
  <div class="q-pa-md">

    <q-form @submit="onSubmit" class="q-gutter-md">
        <div class="col row justify-center">
            <p>{{ $t("login.username") }}: {{ user.username }}</p>
        </div>
        <div class="col row justify-center">
            <p>{{ $t("login.remark") }}: {{ user.username }}</p>
        </div>
        <div style="margin-top:20px"></div>

        <div v-if="password != new_password_again" class="col row justify-center">
            <p style="color: red">{{ $t("login.password_not_match") }}</p>
        </div>
        <div class="col row justify-center">
            <div style="width: 250px">
                <q-input v-model="password" stack-label :label="$t('login.new_password') + '(*)'" type="password" />
            </div>
        </div>
        <div class="col row justify-center">
            <div style="width: 250px">
                <q-input v-model="new_password_again" stack-label :label="$t('login.new_password_again') + '(*)'" type="password" />
            </div>
        </div>
        <div style="height: 20px"></div>
        <div class="col row justify-center">
            <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.cancel')" type="submit" color="secondary" v-on:click="$router.go(-1)"/>
            <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.ok')" type="submit" color="secondary" v-on:click="commit()" />
        </div>
    </q-form>
  </div>
  <Alert v-if="alert_show" :messages="alert_messages" :key="alert_messages"></Alert>
</template>

<script>
import Alert from '@/components/Alert.vue';

export default {
    components: {Alert},
    data () {
        return {
            user_id: this.$route.params.user_id,
            user: {},

            password: '',
            new_password_again: '',

            alert_show: false,
            alert_messages: [],
        }
    },
    created () {
        this.retrieveUser();
    },
    methods: {
        alert(messages) {
            this.alert_messages = messages;
            this.alert_show = true;
        },

        async retrieveUser() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id)
            let data = await response.json();
            this.user = data;
        },
        async update_info(key, value) {
            let data = {};
            data[key] = value;
            try {
                let response = await this.$fetch('/api/v1/user/'+this.user_id, {
                    body: JSON.stringify(data),
                    method: 'PUT'
                });
                if (response.status == 200) {
                    this.$router.go(-1);
                } else {
                    throw "response.status: " + response.status;
                }
            } catch (e) {
                this.alert([this.$t('error.retry')]);
            }
        },

        async commit() {
            if (this.password == this.new_password_again) {
                let result = this.$valid_password(this.password);
                if (result.length == 0) {
                    this.update_info('password', this.password);
                }
                else {
                    let messages = [];
                    for (let item of result) {
                        messages.push(item.message);
                    }
                    this.alert(messages);
                }
            }
        } 
    }
}
</script>
